import React, { useState, useEffect } from "react";
import { Auth, API, Hub } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { getS3Image } from "./libs/awsLib";
import Routes from "./Routes";
import "./App.css";
import { Home, Globe } from 'react-feather';
import defaultAvatar from "./assets/avatar.png"
import { Icon } from '@iconify/react';
import homeIcon from '@iconify-icons/foundation/home';
import webIcon from '@iconify-icons/mdi/web';
import harrisImg from "./assets/harris_aacimg.png";
import bidenImg from "./assets/biden_aacimg.png";
import trumpImg from "./assets/trump_aacimg.png";

function App(props) {
  const [width, setWidth] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [user, setUser] = useState({});
  const [cognito, setCognito] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [pollResponses, setPollResponses] = useState([]);
  const [communities, setCommunities] = useState([]);
  const [myCommunities, setMyCommunities] = useState([]);
  const [community, setCommunity] = useState(null);
  const [isMember, setIsMember] = useState(false);
  const [updatingMembership, setUpdatingMembership] = useState(false);

  useEffect(() => {
    async function onLoad() {
      Hub.listen("auth", ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            userHasAuthenticated(true);
            break;
          case "signOut":
            userHasAuthenticated(false);
            break;
          case "customOAuthState":
            window.location.replace(decodeURIComponent(data).replace(/\+/g,"-"));
            break;
          default:
            break;
        }
      });

      try {
        await Auth.currentSession();
        userHasAuthenticated(true);
      }
      catch(e) {
      }

      setWidth(getWidth());
      window.addEventListener("resize", () => {setWidth(getWidth())});

      setIsAuthenticating(false);
    }

    onLoad();
  }, []);

  function getWidth() {
    let width;
    if (!window.matchMedia(`(min-width: 576px)`).matches)
      width = 'sm';
    else if (!window.matchMedia(`(min-width: 768px)`).matches)
      width = 'md';
    else if (!window.matchMedia(`(min-width: 992px)`).matches)
      width = 'lg';
    else
      width = 'xl';
    return width;
  }

  //////////// USER DATA FUNCTIONS

  async function getUser() {
    // get cognito data
    const cognito = await Auth.currentAuthenticatedUser({bypassCache: true});
    const isExternal = cognito.username.includes('Facebook') || 
                          cognito.username.includes('Google') ? true : false;
    setCognito({
      UID: cognito.attributes.sub,
      email: cognito.attributes.email,
      external: isExternal
    });

    // get user data from ddb
    const user = await API.get('users', 'get_user');
    setUser(user);

    const url = await getS3Image(user.UID);
    setAvatar(url);

    // update user's email in ddb (if changed on fb/google)
    if (isExternal && cognito.attributes.email && cognito.attributes.email !== user.email) {
      let body = {
        email: cognito.attributes.email,
        UID: user.UID
      };

      API.post('users', 'update_user', {body: body});
    }

    // get poll responses
    const params = {queryStringParameters: {UID: user.UID}};
    API.get('polls', 'polls_responses', params).then(resp => {
      const data = resp;
      setPollResponses(data);
    });

    // get communities
    API.get('comms', 'communities').then(resp => {
      let comms = [];
      let myComms = [];
      resp.forEach(comm => {
        if (user.communities && user.communities.includes(comm.comm_id))
          myComms.push(comm);
        else
          comms.push(comm);
      });
      comms.sort((a, b) => a.comm_name.localeCompare(b.comm_name));
      myComms.sort((a, b) => a.comm_name.localeCompare(b.comm_name))
      setCommunities(comms);
      setMyCommunities(myComms);
    });
  }

  useEffect(() => {
    if (isAuthenticating) return; 
    if (isAuthenticated) {
      getUser();
      // this probably can be combined
      if (user) {
        if (user.UID) {
          window.dataLayer.push({
            'user_id': `${user.UID}`,
          });
          const userObj = {id: user.UID, ip_address: "{{auto}}"}
          if (user.fullname) {
            userObj.username = user.fullname
          }
          if (user.email) {
            userObj.email = user.email
          }
        }
      }
    } else {
      setUser({});
      // get communities
      API.get('comms', 'communities').then(resp => {
        let comms = [];
        let myComms = [];
        resp.forEach(comm => {
          comms.push(comm);
        });
        setCommunities(comms);
        setMyCommunities(myComms);
      });
      setCognito(null);
      setAvatar(null);
      if (localStorage.getItem('pollResponses'))
        setPollResponses(JSON.parse(localStorage.getItem('pollResponses')));
      else
        setPollResponses([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthenticating]);

  //////////// RENDER FUNCTIONS

  function renderHeader() {
    const branding = (
      <Navbar.Brand>
        <LinkContainer className="link logo" to="/home">
          <span>icitizen</span>
        </LinkContainer>
      </Navbar.Brand>
    );

    const display = width === 'sm' ? 'none' : 'flex';
    const img_src = avatar ? avatar : defaultAvatar

    const userData = (
        isAuthenticated ? (
          <div className="userdata" style={{marginTop: "-4px", marginRight: '20px'}}>
            <LinkContainer className="link" to="/account" style={{paddingTop: '7px'}}>
              <div>
                <strong style={{marginRight: '5px'}}>{ user.icoins }</strong> i¢
              </div>
            </LinkContainer>
            <LinkContainer className="link" to="/account">
              <div className="userdata">
                <div
                  style={
                    {backgroundImage: `url(${img_src}), url(${defaultAvatar})`}
                  }
                  className="navbar-avatar"
                />
              </div>
            </LinkContainer>
          </div>
        ) : (
          <React.Fragment>
            <Link to="/login" className="login-btn">
              login
            </Link>
          </React.Fragment>
        )
    )

    return (
      <Navbar fixedTop={true} fluid>
          <div style={{display: 'flex'}}>
            <Navbar.Header>
              { branding }
            </Navbar.Header>
            <Nav style={{display: display, marginLeft: '25px'}}>
              <LinkContainer to="/home">
                <NavItem>
                  <Icon icon={homeIcon} style={{width: '26px', height: '26px', margin: '0 0 0 -4px'}}/>
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/communities">
                <NavItem>
                <Icon icon={webIcon} style={{width: '26px', height: '26px', margin: '0 0 0 -6px'}}/>
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/trumpbot">
                <NavItem>
                  <img src={trumpImg} alt="Donald Trump" style={{width: '28px', height: '28px', borderRadius: "50%", overflow: "hidden", margin: '0 0 0 -6px'}}/>
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/harrisbot">
                <NavItem>
                  <img src={harrisImg} alt="Kamala Harris" style={{width: '28px', height: '28px', borderRadius: "50%", overflow: "hidden", margin: '0 0 0 -6px'}}/>
                </NavItem>
              </LinkContainer>
            </Nav>
            { userData }
          </div>
      </Navbar>
    );
  }

  function renderNav() {
    const navbar_cn = width === 'sm' ? 'navbtn-sm' : 'navbtn';
    const nav_cn = width === 'sm' ? 'navbar-nav-sm' : null;
    const isBottom = width === 'sm' ? true : false;
    const display = width === 'sm' ? 'block' : 'none';

    return (
      <Navbar 
        className={navbar_cn + " container"} 
        style={{zIndex: 1000, display: display}}
        fixedBottom={isBottom} 
        fixedTop={!isBottom} 
        fluid
      >
        <Nav className={nav_cn}>
          <LinkContainer to="/home">
            <NavItem>
              <Home className="nav-icon" size={18} /> 
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/communities">
            <NavItem>
              <Globe className="nav-icon" size={18} /> 
            </NavItem>
          </LinkContainer>
          <LinkContainer to="/trumpbot">
                <NavItem>
                  <img className="nav-icon"src={trumpImg} alt="Donald Trump"style={{margin: 'auto',width: '20px', height: '20px', borderRadius: "50%", overflow: "hidden"}}/>
                </NavItem>
              </LinkContainer>
              <LinkContainer to="/harrisbot">
                <NavItem>
                  <img className="nav-icon"src={harrisImg} alt="Kamala Harris" style={{margin: 'auto',width: '20px', height: '20px', borderRadius: "50%", overflow: "hidden"}}/>
                </NavItem>
          </LinkContainer>
        </Nav>
      </Navbar>
    );
  }

  return (
    (
      <div className={width === 'sm' ? "App-sm container" : "App container"}>
        { renderHeader() }
        { renderNav() }
        <Routes appProps={
          { 
            width,
            isAuthenticating,
            isAuthenticated, userHasAuthenticated,
            user, setUser,
            cognito, setCognito,
            avatar, setAvatar,
            pollResponses, setPollResponses,
            communities, myCommunities, setMyCommunities,
            community, setCommunity, isMember, setIsMember, updatingMembership, setUpdatingMembership, 
          }
        } />
      </div>
    )
  );
}

export default withRouter(App);

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { querystring } from "../libs/querystring"

export default function UnauthenticatedRoute({ component: C, appProps, ...rest }) {
  let redirect = querystring("redirect");
  if (redirect) redirect = decodeURIComponent(redirect);
  return (
    <Route
      {...rest}
      render={props =>
        !appProps.isAuthenticated
          ? <C {...props} {...appProps} />
          : <Redirect
              to={redirect === "" || redirect === null ? "/home" : redirect}
            />}
    />
  );
}

import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import Loader from "./components/Loader";

const Login = React.lazy(() => import('./containers/user/Login'));
const Signup = React.lazy(() => import('./containers/user/Signup'));
const Forgot = React.lazy(() => import('./containers/user/Forgot'));
const Account = React.lazy(() => import('./containers/user/Account'));

const PollAnswer = React.lazy(() => import('./containers/polls/PollAnswer'));
const PollBuilder = React.lazy(() => import('./containers/polls/PollBuilder'));
const PollResults = React.lazy(() => import('./containers/polls/PollResults'));

const Question = React.lazy(() => import('./containers/questions/Question'));

const Article = React.lazy(() => import('./containers/articles/Article'));

const Privacy = React.lazy(() => import('./containers/Privacy'));
const Terms = React.lazy(() => import('./containers/Terms'));

const Communities = React.lazy(() => import('./containers/communities/Communities'));
const Community = React.lazy(() => import('./containers/communities/Community'));

const Rules = React.lazy(() => import('./containers/Rules'));

const NotFound = React.lazy(() => import('./containers/NotFound'));

const Unsub = React.lazy(() => import('./containers/Unsub'));

const Home = React.lazy(() => import('./containers/Home'));

const AskACandidateInfo = React.lazy(() => import('./containers/askacandidate/AskACandidateInfo'));
const TrumpBot = React.lazy(() => import('./components/TrumpBot'));
const BidenBot = React.lazy(() => import('./components/BidenBot'));

export default function Routes({ appProps }) {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path="/"> <Redirect to="/home" /> </Route>
        <AppliedRoute path="/home" exact component={Home} appProps={appProps} />
        <AppliedRoute path="/answer/:poll_id" exact component={PollAnswer} appProps={appProps} />
        <AppliedRoute path="/results/:poll_id" exact component={PollResults} appProps={appProps} />
        <AppliedRoute path="/articles/:article_id" exact component={Article} appProps={appProps} />
        <AppliedRoute path="/questions/:question_id" exact component={Question} appProps={appProps} />
        <AuthenticatedRoute path="/build" exact component={PollBuilder} appProps={appProps} />

        <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
        <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
        <UnauthenticatedRoute path="/forgot" exact component={Forgot} appProps={appProps} />
        <AuthenticatedRoute path="/account" exact component={Account} appProps={appProps} />

        <AppliedRoute path="/communities" exact component={Communities} appProps={appProps} />
        <AppliedRoute path="/communities/:comm_id" exact component={Community} appProps={appProps} />
        
        <AppliedRoute path="/rules" exact component={Rules} appProps={appProps} />

        <AppliedRoute path="/privacy" exact component={Privacy} appProps={appProps} />
        <AppliedRoute path="/terms" exact component={Terms} appProps={appProps} />

        <AppliedRoute path="/unsub" exact component={Unsub} appProps={appProps} />

        {/*<AppliedRoute path="/chatbots/info" exact component={AskACandidateInfo} appProps={appProps} />*/}
        <AppliedRoute path="/trumpbot" exact component={TrumpBot} appProps={appProps}/>
        <AppliedRoute path="/harrisbot" exact component={BidenBot} appProps={appProps}/>
        
        {/* Finally, catch all unmatched routes */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

import React from "react";
import { Route, Redirect } from "react-router-dom";
import Loader from "./Loader";

export default function AuthenticatedRoute({ component: C, appProps, ...rest }) {
  return (
    <Route
      {...rest}
      render={props => {
        if (appProps.isAuthenticating) {
          return <Loader />
        }
        else if (appProps.isAuthenticated) {
          return <C {...props} {...appProps} />
        }
        else {
          return <Redirect
                    to={`/login?redirect=${props.location.pathname}${props.location.search}`}
                  />
        }
      }}
    />
  );
}
